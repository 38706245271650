<script setup lang="ts">
import { useMagicKeys, whenever } from '@vueuse/core'
import { computed, ref } from 'vue'
import { SearchIcon } from 'vue-tabler-icons'
import { useTheme } from 'vuetify'
import { ElasticCardControllerApi } from '~/api/card/controllers/elastic-card-controller-api'
import type { CardES } from '~/api/card/models/card-es'
import { withAxiosToken } from '~/composables/utils'

const { focused = false } = defineProps<{ focused?: boolean }>()

const emit = defineEmits(['clean'])

const loading = ref(false)
const foundCards = ref<CardES[]>([])
const selectedCard = ref<CardES | null>(null)
const autocompleteCards = ref()

whenever(useMagicKeys().escape, () => {
  clickOutsideSearch()
})

function clickMe() {
  console.log('clickMe 🔎')
}

function clickOutsideSearch() {
  console.log('Clicked outside')
// if (autocompleteCards.value.isFocused) {
//   autocompleteCards.value.search = ''
//   selectedCard.value = null
//   emit('clean')
// }
}

// function focus() { autocompleteCards.value.focus() }

function submitOnEnterPressed() {
  console.log(`ENTER pressed 'autocomplete_cards': 🔎search string: ${autocompleteCards.value.search}`)
}

// TODO: move inside composable
const folderLogo = `${useRuntimeConfig().public.STATIC}/img/logo/png`
const logo = computed<string>(() => {
  const darkSuffix: string = useTheme().global.name.value === 'dark' ? '--dark' : ''
  return `${folderLogo}/logo_only${darkSuffix}.png`
})

const isEnoughCharsForSearch = computed<boolean>(() => {
  if (!autocompleteCards.value)
    return false

  const charsInInputField: number = autocompleteCards.value.search.length
  return charsInInputField >= 4
})

const logorotation = computed<string>(() => {
  const charsInInputField: number = autocompleteCards.value.search.length
  const degrees = -charsInInputField * 45
  return `rotate(${degrees}deg)`
})

async function findCardsByChars(chars: string) {
  if (chars == null || chars.length < 4) {
    // console.info('Ждем > 4 букв для поиска...')
    return
  }
  loading.value = true
  try {
    foundCards.value = (await new ElasticCardControllerApi(await withAxiosToken()).searchCardsInElastic(chars)).data
  }
  catch (e) {
    console.error(`Elastic error for request=${chars}.`, e)
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <v-autocomplete
    ref="autocompleteCards"
    v-model="selectedCard"
    menu-icon=""
    autocomplete="off"
    :autofocus="focused"
    :items="foundCards" :loading="loading"
    placeholder="Find colab" item-text="title" item-value="description"
    color="accent" variant="outlined" density="compact"
    no-filter clearable
    hide-details
    cache-items
    @update:search="findCardsByChars"
    @click:clear="emit('clean')"
    @click:outside="clickOutsideSearch"
    @click:append-inner="clickMe"
    @keyup.enter.exact.prevent="submitOnEnterPressed"
  >
    <!-- TODO return-object   -->
    <!-- :append-inner-icon="mdiMagnify"   -->
    <template #loader>
      <v-fade-transition leave-absolute>
        <v-progress-linear :active="loading" color="accent" absolute indeterminate />
      </v-fade-transition>
    </template>

    <template #no-data>
      <v-list-item title="Press ↵ to find colabs 🔎" />
    </template>

    <template #item="{ props, item }">
      <v-list-item v-bind="props">
        <template #prepend>
          <v-avatar>
            <v-img cover :src="item.raw.img" />
          </v-avatar>
        </template>

        <template #title>
          <p v-html="item.raw.title" />
        </template>

        <template #subtitle>
          <p v-html="item.raw.description" />
        </template>

        <template v-if="item.raw.tags" #append>
          <v-chip color="accent">
            {{ [...item.raw.tags][0] }}
          </v-chip>
        </template>
      </v-list-item>
    </template>

    <template #append-inner>
      <v-img v-if="!isEnoughCharsForSearch" :src="logo" class="rotate" height="24" width="24" alt="logo in search input field" />
      <v-icon v-else id="search_submit-button" class="mx-1 search-inner-right" @click="clickMe">
        <SearchIcon class="rotate" />
      </v-icon>
      <!--      <Search v-else id="search_submit-button" class="mx-1 search-inner-right row-pointer" @click="clickMe" /> -->
    </template>

    <!-- TODO: right outer button (for choosing category?):
          <template #append>
            <v-menu>
              <template #activator="{ props }">
                <v-btn v-bind="props" class="mt-n2">
                  <v-icon :icon="mdiMenu" start/>
                  Menu
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="pa-6">
                  <v-btn size="large" variant="text" @click="clickMe">
                    <v-icon :icon="mdiTarget" start/>
                    Click me
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
-->
  </v-autocomplete>
</template>

<style scoped lang="scss">
.rotate {
  transition-duration: 0.8s;
  transition-property: transform;
  transform: v-bind(logorotation);
}

.search-inner-right {
  font-size: 1.3em;
  color: rgb(var(--v-theme-accent));
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 30%));
}
</style>
