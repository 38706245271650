<script setup lang="ts">
import type { TablerIconComponent } from 'vue-tabler-icons'
import { computed, ref } from 'vue'
import { MoonStarsIcon, SunIcon } from 'vue-tabler-icons'
import { useTheme } from 'vuetify'
import { UserControllerApi } from '~/api/auth/controllers/user-controller-api'
import { setUser, useUser } from '~/composables/auth/use-customer-info'
import { isDark, withAxiosToken } from '~/composables/utils'

const user = useUser()

const theme = useTheme()

const isDarkTheme = ref<boolean>(isDark())

const icon = computed<TablerIconComponent>(() => isDarkTheme.value ? MoonStarsIcon : SunIcon)
const currentModeName = computed<string>(() => `${theme.global.name.value} mode`)

const colourSwitch = computed<string>(() => isDarkTheme.value ? 'blue-grey-darken-2' : '')
const colourIcon = computed<string>(() => isDarkTheme.value ? 'primary' : 'yellow-darken-4')

async function toggleThemeColor() {
  theme.global.name.value = isDarkTheme.value ? 'dark' : 'light'

  if (user.value) {
    await new UserControllerApi(await withAxiosToken()).updateDarkTheme(isDarkTheme.value)
    // TODO: check update only 1 field
    await setUser({ ...user.value, isDark: isDarkTheme.value })
  }
}

async function toggle() {
  isDarkTheme.value = !isDarkTheme.value
  await toggleThemeColor()
}
</script>

<template>
  <v-switch
    v-model="isDarkTheme"
    hide-details
    :color="colourSwitch"
    @input="toggleThemeColor()"
  >
    <template #prepend>
      <v-icon :color="colourIcon" @click="toggle()">
        <component :is="icon" />
      </v-icon>
    </template>

    <template #label>
      {{ currentModeName }}
    </template>
  </v-switch>
</template>
