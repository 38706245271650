<script setup lang="ts">
import type { TablerIconComponent } from 'vue-tabler-icons'
import { computed, ref, toRaw } from 'vue'
import { GridDotsIcon, Menu2Icon, MenuIcon, SearchIcon } from 'vue-tabler-icons'
import { useDisplay } from 'vuetify'
import type { CardES } from '~/api/card/models/card-es'
import GuestBlock from '~/components/main/header/GuestBlock.vue'
import LogoMain from '~/components/main/header/LogoMain.vue'
import PrivateBlock from '~/components/main/header/PrivateBlock.vue'
import { useSidebarStore } from '~/stores/sidebar'

const sidebar = useSidebarStore()
const { smAndDown, mdAndUp } = useDisplay()

const showSearch = ref(false)
const searchFocused = ref(false)
const sidebarRight = ref(false)

const iconMenu = computed<TablerIconComponent>(() => sidebar.sidebarLeftOpened ? MenuIcon : Menu2Icon)

function toggleSearch() {
  showSearch.value = !showSearch.value
  if (showSearch.value)
    searchFocused.value = true
}

function selectFoundCard(cardRef: any) {
  const selectedCard: CardES = toRaw(cardRef)
  console.log(`🔎 Selected card: ${selectedCard.id}`)
  // console.dir(selectedCard)
}

function clearInput() {
  console.log('👀 Input is cleared')
  showSearch.value = false
}
</script>

<template>
  <v-app-bar elevation="0" class="full-width" color="surface">
    <v-app-bar-nav-icon :icon="iconMenu" color="lighten-2" @click.stop="sidebar.SIDEBAR_L_TOGGLE(mdAndUp)" />

    <LogoMain v-show="!showSearch" :show-logo="mdAndUp" :class="mdAndUp ? 'mx-2' : 'mr-1'" />

    <v-btn v-if="smAndDown && !showSearch" variant="text" icon @click="toggleSearch">
      <SearchIcon />
    </v-btn>

    <v-spacer v-if="mdAndUp && !showSearch" />

    <Transition name="slide-fade">
      <SearchAllCards
        v-if="mdAndUp || showSearch"
        :focused="searchFocused"
        :class="smAndDown ? 'mx-3' : ''"
        @update:model-value="selectFoundCard"
        @clean="clearInput"
      />
    </Transition>
    <v-spacer v-if="!showSearch" />

    <nav v-show="!showSearch" class="pr-1">
      <v-no-ssr>
        <PrivateBlock />
        <GuestBlock />
      </v-no-ssr>
      <v-btn variant="text" color="primary" class="mx-1" icon @click.stop="sidebarRight = !sidebarRight">
        <GridDotsIcon size="18" />
      </v-btn>
    </nav>
  </v-app-bar>

  <v-navigation-drawer v-model="sidebarRight" location="right" temporary>
    <MainSidebarRight />
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.full-width {
  width: 100dvw;
}

.no-decoration {
  color: inherit;
  text-decoration: none;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-enter-from {
  opacity: 0;
  transform: translateX(2em);
}
</style>
